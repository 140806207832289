import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useProduct, useProductByHandle } from '@backpackjs/storefront';

import { Link } from '..';

export function ProductGroupItem({ product }) {
  const [isAvailable, setIsAvailable] = useState(true);
  const [isActive, setIsActive] = useState(false);

  // get current product
  const currentProduct = useProduct();

  const linkedProduct = product?.linkedProduct;

  const { product: packProduct } = useProductByHandle({
    handle: linkedProduct?.handle,
  });

  useEffect(() => {
    if (!packProduct) return;

    const availableInventory = packProduct?.totalInventory ?? 0;
    setIsAvailable(availableInventory > 0 || !packProduct?.tracksInventory);

    setIsActive(linkedProduct?.handle === currentProduct?.handle);
  }, [currentProduct, packProduct]);

  const productURL = `/products/${linkedProduct?.handle}`;

  const productTitle = product?.linkedProductTitle;

  const classes =
    'relative inline-block min-w-[3.5rem] overflow-hidden border px-[20px] py-[10px] text-text transition hover:border-text hover:bg-white md:hover:border-text';

  return (
    <div className="whitespace-nowrap">
      {isActive && (
        <span
          className={`${classes} cursor-pointer border-black bg-white shadow-[inset_0_0_0_1px_#000000]`}
        >
          {productTitle}
        </span>
      )}

      {!isActive && (
        <Link
          className={`${
            isAvailable
              ? 'border-[#797D86] bg-[#E6F0F8]'
              : 'pointer-events-none opacity-50'
          }
          ${classes}`}
          aria-label={productTitle}
          href={productURL}
        >
          {!isAvailable && (
            <div className="absolute inset-0 top-[50%] h-[1px] w-[calc(100%+20px)] -rotate-[18deg] border-t border-t-text bg-red-200 opacity-30" />
          )}
          {productTitle}
        </Link>
      )}
    </div>
  );
}

ProductGroupItem.displayName = 'ProductGroupItem';
ProductGroupItem.propTypes = {
  product: PropTypes.shape({
    linkedProductTitle: PropTypes.string,
    linkedProduct: PropTypes.object,
  }),
};
