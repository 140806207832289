import { useEffect, useRef } from 'react';
import ScrollyVideo from 'scrolly-video/dist/ScrollyVideo.cjs.jsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useInView } from 'react-intersection-observer';
import { ReviewStars, Image, Markdown } from '../../snippets';
import Schema from './ScrollyVideoSection.schema';

const getCardWrapperClasses = (index) => {
  const justifyEndClass = index % 2 === 1 ? 'justify-end' : 'justify-start text-right';
  return `scrolly-video__card-wrap flex items-start z-[1] relative md:mx-[2rem] lg:mx-[0rem] h-[100vh] ${justifyEndClass}`;
};

export default function ScrollyVideoSection({ cms }) {
  const {
    videoLink,
    videoCards,
    eyebrowText,
    title,
    videoCardsMobile,
  } = cms;

  const videoRefs = useRef([]);

  const handleVideoInView = (inView, index) => {
    const video = videoRefs.current[index];
    if (video) {
      if (inView) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const cardsWithReviews = videoCardsMobile.filter(card => card.review);

  return (
    <div className="scrolly-video bg-white relative">
      <div
        className="scrolly-video__heading text-[#202635] mx-auto md:top-[7rem] mb-0 text-center pt-16 md:pb-10 md:absolute md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 w-full bg-transparent md:z-[1] px-2"
      >
        {eyebrowText && (
          <p className="mb-4 text-md max-sm:text-[0.813rem]">{eyebrowText}</p>
        )}
        {title && (
          <h2 className="font-light max-sm:text-[2rem] text-[3.5rem] leading-snug">{title}</h2>
        )}
      </div>

      {/* Desktop */}
      <div className="scrolly-video__desk h-[700vh] hidden md:block px-contained">
        <ScrollyVideo src={videoLink} />
        {videoCards.map((card, index) => (
          <div
            key={index}
            className={getCardWrapperClasses(index)}
            style={{
              marginBottom: `${card.cardSpacing}vh`,
            }}
          >
            <div className={`scrolly-video__card-info text-[#202635] bg-gradient-to-t from-[#ffffff] to-[#e6f0f8] rounded-lg p-[18px] shadow-xl sm:max-w-[275px] lg:max-w-[375px] sticky top-[30vh]`}>
              <div className={`text-lg font-normal mb-2 inline-flex items-center ${index % 2 === 1 ? 'flex-row-reverse' : 'flex-row'}`}>
                {card.title && (
                  <h3 className="text-xl font-normal">{card.title}</h3>
                )}
                {card.icon && (
                  <Image
                    className={`mx-auto max-sm:w-[18px] pt-0 ${index % 2 === 1 ? 'mr-2' : 'ml-2'}`}
                    sizes="48px"
                    width="24px"
                    height="24px"
                    src={card.icon.src}
                    alt={card.icon.alt}
                  />
                )}
              </div>
              {card.subtitle &&
                <Markdown className="scrolly-video__desk__subtitle">
                  {card.subtitle}
                </Markdown>
              }
              {card.reviewIntro &&
                <p className="text-[#0064B7] text-md font-light mb-4 font-normal">{card.reviewIntro}:</p>
              }
              {card.review && (
                <div className="scrolly-video__card border border-[#202635] border-[0.0625rem] p-[1rem] rounded-lg text-left">
                  <ReviewStars rating={card.rating} color="#2179C2" />
                  {card.reviewName &&
                    <p className="text-md font-normal mt-2 mb-2">{card.reviewName}</p>
                  }
                  {card.review &&
                    <p className="text-sm font-light mb-4">{card.review}</p>
                  }
                  {card.date &&
                    <p className="text-xs">{card.date}</p>
                  }
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Mobile */}
      <div className="scrolly-video__mob">
        {cardsWithReviews.length > 0 && (
          <Swiper
            slidesPerView={1.3}
            centeredSlides
            loop
            spaceBetween={7}
          >
            {videoCardsMobile.map((card, index) => (
              <>
                {card.review && (
                  <SwiperSlide key={index} className="pt-24 hidden max-md:block">
                    {({ isActive }) => (
                      <>
                        <div className="text-center mb-4">
                          {card.reviewIntro &&
                            <p className="text-[#0064B7] text-md font-light mb-4 font-normal">{card.reviewIntro}:</p>
                          }
                          {card.review && (
                            <div className="scrolly-video__card flex flex-col items-start justify-center h-[17rem] bg-[#E6F0F8] p-[25px] rounded-lg shadow-lg text-left">
                              <ReviewStars rating={card.rating} color="#2179C2" />
                              {card.reviewName && <p className="text-lg font-normal mt-2 mb-2">{card.reviewName}</p>}
                              {card.review && <p className="text-md font-light mb-4">{card.review}</p>}
                              {card.date && <p className="text-sm">{card.date}</p>}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </SwiperSlide>
                )}
              </>
            ))}
          </Swiper>
        )}

        <div className="scrollbar-hide hidden max-md:block">
          {videoCardsMobile.map((card, index) => {
            const isFirstItem = index === 0;
            const { ref, inView } = useInView({
              threshold: 0.5,
              triggerOnce: false,
            });

            useEffect(() => {
              handleVideoInView(inView, index);
            }, [inView]);

            return (
              <>
                <div
                  key={index}
                  className="pb-20 top-0 sticky relative flex flex-col justify-center h-screen pt-[var(--header-height)] items-center bg-white"
                >
                  {!isFirstItem &&
                    <div className="absolute top-0 w-full h-[5vh] bg-gradient-to-t from-[#ffffff] to-[#e6f0f8]"></div>
                  }
                  <video
                    className="mx-auto w-full max-w-[400px]"
                    ref={(el) => {
                      if (el) {
                        videoRefs.current[index] = el;
                        ref(el);
                      }
                    }}
                    src={card.cardVideoLink}
                    muted
                    loop
                    webkit-playsinline
                    playsInline
                  />
                  <div className="scroll-video__mob__bottom text-center mt-4 mx-5">
                    {card.icon && (
                      <Image
                        className={`mx-auto max-sm:w-[24px] pt-0 mb-2`}
                        sizes="48px"
                        width="24px"
                        height="24px"
                        src={card.icon.src}
                        alt={card.icon.alt}
                      />
                    )}
                    {card.title &&
                      <h3 className="text-[18px] mb-2 font-normal">{card.title}</h3>
                    }
                    {card.subtitle &&
                      <Markdown className="scrolly-video__mob__subtitle">{card.subtitle}</Markdown>
                    }
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

ScrollyVideoSection.Schema = Schema;
