import { useMemo } from 'react';
import equal from 'fast-deep-equal';

export function ProductOptionValue({
  inventory,
  isSelected,
  newSelectedOptions,
  product,
  setSelectedOptions,
  value,
}) {
  const variantFromOptionValue = useMemo(() => {
    return product.variants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
  }, [newSelectedOptions, product.variants]);

  const optionValueIsAvailable =
    inventory?.variants?.[variantFromOptionValue?.id]?.availableForSale ||
    false;
  const disabled = !variantFromOptionValue;

  return (
    <li className="product-option-item">
      <button
        aria-label={value}
        className={`relative inline-block min-w-[3.5rem] overflow-hidden border px-[20px] py-[10px] text-text transition hover:border-text hover:bg-white md:hover:border-text
        ${
          optionValueIsAvailable
            ? 'border-[#797D86] bg-[#E6F0F8]'
            : 'pointer-events-none opacity-50'
        }
        ${
          isSelected
            ? 'cursor-pointer border-black bg-white shadow-[inset_0_0_0_1px_#000000]'
            : ''
        }`}
        disabled={disabled}
        onClick={() => {
          if (isSelected) return;
          setSelectedOptions(newSelectedOptions);
        }}
        type="button"
      >
        {!optionValueIsAvailable && (
          <div className="absolute inset-0 top-[50%] h-[1px] w-[calc(100%+20px)] -rotate-[18deg] border-t border-t-text bg-red-200 opacity-30" />
        )}

        {value}
      </button>
    </li>
  );
}

ProductOptionValue.displayName = 'ProductOptionValue';
