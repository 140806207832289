import { Body, Button, Headline } from '../../snippets';
import useMediaQuery from '../../utils/useMediaQuery';
import Schema from './HeroBannerWithNewsTicker.schema';

export default function HeroBannerWithNewsTicker({ cms }) {
  const {
    backgroundImage,
    backgroundMobileImage,
    newsticker,
    heading,
    cta,
    lightMode,
  } = cms;

  const isMobile = useMediaQuery('(max-width: 767px)');

  const mobileImageBackup = backgroundImage?.src
    ? `${backgroundImage.src}&width=800&quality=75`
    : null;
  const mobileImage = backgroundMobileImage?.src
    ? `${backgroundMobileImage.src}&width=800&quality=75`
    : mobileImageBackup;
  const desktopImage = backgroundImage?.src
    ? `${backgroundImage.src}&width=2000`
    : null;

  return (
    <>
      <section
        className="h-[540px] bg-cover bg-left-top print:hidden lg:h-[574px] lg:bg-center"
        style={{
          backgroundImage: `url(${
            isMobile && mobileImage ? mobileImage : desktopImage
          })`,
        }}
      >
        <div className="px-contained flex h-full flex-col gap-10 py-12 lg:max-w-[700px] lg:justify-center">
          {heading && (
            <Headline
              variant={isMobile ? 'h1' : 'h2'}
              className={`${lightMode ? 'text-white' : 'text-text'}`}
            >
              {heading}
            </Headline>
          )}
          {cta && (
            <div>
              <Button
                theme={`${lightMode ? 'light' : 'secondary'}`}
                variant="filled"
                href={cta.url}
              >
                {cta.text}
              </Button>
            </div>
          )}
        </div>
      </section>
      {newsticker?.length > 0 && (
        <>
          <section className="relative flex h-24 items-center overflow-x-hidden bg-[#343B49] print:hidden">
            <div className="absolute inset-0 z-10 bg-gradient-to-r from-[#F1DCC1] to-[#2179C2] mix-blend-multiply" />
            {Array(3)
              .fill()
              .map((_, i) => (
                <div className="animate-news-ticker-1" key={i}>
                  <div className="flex gap-10 px-5 text-white">
                    {newsticker?.map((item, index) => {
                      if (!item?.text) return null;
                      return (
                        <div
                          className="flex items-center gap-x-2"
                          key={`ticker-item-${index}`}
                        >
                          <img
                            src={item?.icon?.src}
                            alt={item?.icon?.alt ?? 'News Ticker Icon'}
                            className="h-5 w-5 object-contain"
                          />
                          <Body>{item?.text}</Body>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
          </section>
          <div className="h-1 bg-gradient-to-r from-[#F1DCC1] to-[#2179C2]" />
        </>
      )}
    </>
  );
}

HeroBannerWithNewsTicker.displayName = 'HeroBannerWithNewsTicker';
HeroBannerWithNewsTicker.Schema = Schema;
