import { Eyebrow, Headline, Image, Markdown } from '../../snippets';
import useMediaQuery from '../../utils/useMediaQuery';
import { Schema } from './ImageWithTextLanding.schema';

export function ImageWithTextLanding({ cms }) {
  const {
    eyebrow,
    heading,
    image,
    imageAlignment,
    eyebrowIconImage,
    alignTextWithImage,
    topPadding,
    bottomPadding,
    content,
    darkMode,
    mobileImage,
  } = cms;
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <section className={darkMode ? 'dark' : ''}>
      <div className={`dark:bg-[#202635] dark:text-white ${topPadding ? 'pt-[25px]' : 'md:pt-[0px]'} ${bottomPadding ? 'pb-[55px]' : 'md:pb-[0px]'}`}>
        <div
          className={[
            'px-contained mx-auto max-w-[var(--content-max-width)] pb-[0px] md:pb-[25px] md:pt-[50px] max-md:pt-[25px] }',
          ].join(' ')}
        >
          <div className="unique-technology grid grid-cols-1 lg:grid-cols-2">
            <div
              className={`content-column ${ alignTextWithImage && imageAlignment == 'right' ? 'lg:text-right lg:justify-end' : '' } flex items-center border-t border-t-[#FFFFFF33] p-4 py-[60px] max-lg:px-[0px] max-lg:pt-[10px] max-lg:pb-[25px] order-1 lg:px-[50px] :order-1 lg:border-t-0 ${
                imageAlignment === 'left' ? 'order-1' : 'order-0'
              }`}
            >
              <div>
                <div className={`inline-flex items-center ${imageAlignment === 'right' ? 'lg:flex-row-reverse' : 'flex-row'}`}>
                  {eyebrowIconImage && (
                      <Image
                        className="mx-auto max-sm:w-[18px]"
                        sizes="48px"
                        width="24px"
                        height="24px"
                        src={eyebrowIconImage.src}
                      />
                    )}
                    {eyebrow && (
                      <Eyebrow>{eyebrow}</Eyebrow>
                    )}
                </div>
                {heading && (
                  <Headline
                    variant="h3"
                    element="h2"
                    className='mb-[10px] max-md:text-[22px]'
                  >
                    {heading}
                  </Headline>
                )}

                {content && (
                  <div className="text-[14px] sm:text-[18px] [&_p:not(:first-child)_strong]:border-t [&_p:not(:first-child)_strong]:border-t-[#FFFFFF33] [&_p:not(:first-child)_strong]:pt-6 [&_strong]:mt-6 [&_strong]:block [&_strong]:text-[16px]">
                    <Markdown>{content}</Markdown>
                  </div>
                )}
              </div>
            </div>

            {(image || mobileImage) && (
               <div
                  className={`image-column  order-0 relative block py-6 h-[276px] lg:h-[600px] w-full ${
                    imageAlignment === 'left' ? 'lg:order-0' : 'lg:order-1'
                  }`}
                >
                <Image
                  src={
                    isMobile && mobileImage ? mobileImage.src : image?.src
                  }
                  alt={heading}
                  fill
                  sizes="(min-width: 991px) 60vw, 100vw"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

ImageWithTextLanding.displayName = 'Image With Text Landing';
ImageWithTextLanding.Schema = Schema;
