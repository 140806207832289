export function Schema() {
  return {
    category: 'Article',
    label: 'Article Fifty Fifty Image',
    key: 'articleFiftyFifty',
    // previewSrc:
    //   'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.45.53_20AM.png?v=1684864558',
    fields: [
      {
        name: 'title',
        component: 'text',
        label: 'Title',
        description: 'Leave Blank to hide from Table of Contents',
      },
      {
        name: 'anchorId',
        component: 'text',
        label: 'Table Of Contents Title',
        description: 'Leave Blank to hide from Table of Contents',
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Eyebrow',
        name: 'eyebrow',
        component: 'text',
        defaultValue: 'GET PEACE OF MIND',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue:
          'Shop the most powerful filtration systems on the planet.',
      },
      {
        label: 'Copy',
        name: 'copy',
        component: 'text',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      {
        label: 'Link',
        name: 'link',
        component: 'link',
      },
    ],
  };
}
