import {
  useLocalizedVariant,
  useProductByHandle,
} from '@backpackjs/storefront';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Link } from '../Link';
import { OkendoStarRating } from '../Okendo/OkendoStarRating';
import { ColorVariantSelector } from './ColorVariantSelector';
import { ProductItemMedia } from './ProductItemMedia/ProductItemMedia';
import { ProductItemPrice } from './ProductItemPrice';
import { QuickShop } from './QuickShop';
import { useDataLayerActions } from '../../hooks';

export function ProductItem({
  enabledColorNameOnHover,
  enabledColorSelector,
  enabledQuickShop,
  enabledStarRating,
  handle,
  index,
  isSearchPage,
  onClick,
  swatchesMap,
  disableHoverEffect = false,
  disableBackground = false,
  searchTerm,
}) {
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });
  const { product } = useProductByHandle({
    handle,
    fetchOnMount: inView,
  });
  const { sendClickProductItemEvent } = useDataLayerActions();

  const [variantFromColor, setVariantFromColor] = useState(null);
  const variantParamId =
    variantFromColor?.legacyResourceId || product?.legacyResourceId;
  let variantParam = '';
  if (variantParamId) {
    variantParam = `?variant=${variantParamId}`;
  }
  const variant = variantFromColor || product?.variants?.[0];
  const productUrl = variant
    ? `/products/${variant.product?.handle}${variantParam}`
    : '';
  const color = variant?.selectedOptionsMap?.Color;
  const title = variant?.product?.title;

  const { localized } = useLocalizedVariant({ variant });

  const handleClick = useCallback(() => {
    sendClickProductItemEvent({
      isSearchResult: isSearchPage,
      listIndex: index,
      localized,
      product,
      searchTerm,
      selectedVariant: variant,
    });
    if (typeof onClick === 'function') onClick();
  }, [index, localized, product?.id, searchTerm, variant?.id]);

  const metaFields = product?.metafields;
  let reviews = false;
  metaFields?.forEach((metaField) => {
    if (metaField.key === 'ProductReviewsWidgetSnippet') {
      reviews = true;
    }
  });

  return (
    <div className="group flex h-full flex-col justify-between" ref={ref}>
      <div className="flex flex-col items-start">
        <Link
          aria-label={title}
          className="mb-3 w-full"
          href={productUrl}
          onClick={handleClick}
          tabIndex="-1"
        >
          <ProductItemMedia
            disableBackground={disableBackground}
            disableHoverEffect={disableHoverEffect}
            product={product}
            variant={variant}
            variantFromColor={variantFromColor}
          />
        </Link>

        <div className="min-h-[30px]">
          {enabledStarRating && (
            <div className="mb-[10px]">
              <Link
                aria-label={`Reviews for ${title}`}
                href={productUrl}
                onClick={handleClick}
                tabIndex="-1"
              >
                {reviews && (
                  <div className="okendo-widget-container text-primary [&_.oke-sr-count]:font-light max-md:[&_.oke-sr-count]:text-[10px] [&_svg]:h-[13px] ">
                    <OkendoStarRating productId={product?.legacyResourceId} />
                  </div>
                )}
              </Link>
            </div>
          )}
        </div>

        <Link aria-label={title} href={productUrl} onClick={handleClick}>
          <h3 className="text-sm font-light text-[#24293B] md:text-xl">
            {title}
          </h3>
        </Link>

        {product?.tags?.includes('Affinity Technology') && (
          <div className="mb-2 mt-1 text-sm font-[300] text-[#797D86] md:text-[20px]">
            with Affinity Filtration Technology
          </div>
        )}

        {enabledColorSelector && (
          <ColorVariantSelector
            className="mb-2 mt-[22px]"
            enabledColorNameOnHover={enabledColorNameOnHover}
            product={product}
            setVariantFromColor={setVariantFromColor}
            swatchesMap={swatchesMap}
            variantFromColor={variantFromColor}
          />
        )}

        <ProductItemPrice variant={variant} className="mt-[15px]" />
      </div>

      {enabledQuickShop && (
        <QuickShop
          enabledColorSelector={enabledColorSelector}
          product={product}
          variant={variant}
        />
      )}
    </div>
  );
}

ProductItem.displayName = 'ProductItem';
ProductItem.propTypes = {
  enabledColorNameOnHover: PropTypes.bool,
  enabledColorSelector: PropTypes.bool,
  enabledQuickShop: PropTypes.bool,
  enabledStarRating: PropTypes.bool,
  handle: PropTypes.string,
  index: PropTypes.number,
  isSearchPage: PropTypes.bool,
  onClick: PropTypes.func,
  swatchesMap: PropTypes.object,
};
