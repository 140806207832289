import { useEffect, useState } from 'react';
import { ProductOptions } from './ProductOptions';
import { ProductGroupItem } from './ProductGroupItem';
import { useVariantPrices } from '../../hooks';
import { AddToCart, Image, Markdown } from '../../snippets';
import { RechargeSubscriptionWidget } from '../../snippets/RechargeSubscriptionWidget/RechargeSubscriptionWidget';
import { useGlobalProductPageContext } from '../../contexts/globalProductPageContext';

export function ProductDetails({
  product,
  linkedProductGroups,
  selectedOptions,
  selectedVariant,
  productType,
  setSelectedOptions,
  productDescription,
  productFeatures,
  bottomBannerText,
  bottomBannerHeading,
}) {
  const {
    discount,
    purchaseType,
    selectedSellingPlan,
    productSubscriptionWidget,
    setRecommendedFilterFrequency,
  } = useGlobalProductPageContext();

  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
    purchaseType,
    isPdp: true,
  });
  
  const hideOptions =
    product?.variants?.length === 1 &&
    product.variants[0].title === 'Default Title';

  const defaultRecommendedFrequency =
    productSubscriptionWidget?.defaultRecommendedFrequency;

  useEffect(() => {
    if (defaultRecommendedFrequency) {
      setRecommendedFilterFrequency(defaultRecommendedFrequency);
    }
  }, [defaultRecommendedFrequency]);

  const [displayPrice, setDisplayPrice] = useState(price);
  const [comparePrice, setDisplayComparePrice] =
  useState(compareAtPrice);

  useEffect(() => {
    if (!price) {
      return;
    }

    setDisplayPrice(price);
    setDisplayComparePrice(compareAtPrice);

    if (discount?.enableDiscount) {
      if (!compareAtPrice) {
        setDisplayComparePrice(price);
      }

      let discountPrice = parseFloat(price.replace('$', ''));
      if (discount.discountType === 'fixed') {
        discountPrice -= discount.discountAmount;
      } else {
        discountPrice -= discountPrice * (discount.discountAmount / 100);
      }
      discountPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
        .format(discountPrice)
        .replace('.00', '');
      setDisplayPrice(discountPrice);

    } else if (compareAtPrice) {
      const compareAtPriceNew = compareAtPrice.replace('$', '');
      const priceNew = price.replace('$', '');
      const percentageSaved =
        ((compareAtPriceNew - priceNew) / compareAtPriceNew) * 100;
    }
  }, [price, compareAtPrice, discount, product]);

  return (
    <div className="product-details flex flex-col md:pl-4 lg:pl-10 xl:pl-16">
      {!hideOptions && (
        <ProductOptions
          product={product}
          selectedOptions={selectedOptions}
          selectedVariant={selectedVariant}
          setSelectedOptions={setSelectedOptions}
        />
      )}

      {productType === 'onetimeOnly' && (
        <>
          <hr className="mb-6 mt-6 border-t border-t-[#DDE2E7] p-0" />
          <div className="product-details__product-description__wrap">
            <div className="product-details__product-description mb-5 [&_p]:!text-base">
              <Markdown className="text-[#202635]">
                {productDescription}
              </Markdown>
            </div>
            <div className="product-details__features inline-flex flex-col gap-3">
              {productFeatures.map((feature, index) => (
                <div
                  className="product-details__features-wrap max-sm:text-xs text-[#202635] inline-flex items-center"
                  key={index}
                >
                  <Image
                    className="product-details__image mr-3"
                    sizes="48px"
                    width="24px"
                    height="24px"
                    src={feature.productFeatureIcon.src}
                    alt={feature.productFeatureIcon.alt}
                  />
                  <p>{feature.featureDescription}</p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {linkedProductGroups && linkedProductGroups.length > 0 ? (
        <div className="linked-product-groups">
          {linkedProductGroups.map((group, groupIndex) => {
            return (
              <div
                className={`mb-6 ${group?.linkedProducts && group?.linkedProducts?.length ? 'border-t border-t-[#DDE2E7] mt-6' : ''} border-b border-b-[#DDE2E7]  pb-6 pt-6`}
                key={`linked-product-group-${groupIndex}`}
              >
                {group?.groupName && (
                  <h3 className="text-base font-normal uppercase leading-6">
                    {group?.groupName}
                  </h3>
                )}

                {group?.linkedProducts && group?.linkedProducts?.length && (
                  <div className="pt-4">
                    <ul className="flex flex-wrap gap-2">
                      {group?.linkedProducts?.map(
                        (linkedProduct, linkedProductIndex) => {
                          return (
                            <ProductGroupItem
                              product={linkedProduct}
                              key={`linked-product-${linkedProductIndex}`}
                            />
                          );
                        }
                      )}
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <hr className="mb-6 mt-6 border-t border-t-[#DDE2E7] p-0" />
      )}

      {productSubscriptionWidget && productType !== 'onetimeOnly' && (
        <RechargeSubscriptionWidget
          widgetContent={productSubscriptionWidget}
          widgetType={productType}
          originalDiscountPrice={price}
        />
      )}

      {productType === 'onetimeOnly' && (
        <label
          htmlFor="purchase_type_one-time"
          className="flex cursor-pointer items-center justify-between bg-[#E6F0F8] p-4"
        >
          <div className="flex items-center">
            <input
              id="purchase_type_one-time"
              type="radio"
              name="one_time_purchase"
              className=" hidden"
              value="one-time"
            />
            <div className="mr-4 h-[20px] w-[20px] rounded-full border-[6px] border-textLink" />
            One-time purchase
          </div>
          <div>
            {comparePrice ? (
              <div className="price">
                <span className="mr-2 line-through opacity-50">
                  {comparePrice}
                </span>
                {displayPrice}
              </div>
            ) : (
              `${displayPrice}`
            )}
          </div>
        </label>
      )}

      <AddToCart
        selectedVariant={selectedVariant}
        product={product}
        purchaseType={purchaseType}
        productType={productType}
        selectedSellingPlan={selectedSellingPlan}
        minPrice={displayPrice}
        discountCode={discount?.enableDiscount ? discount.discountCode : null}
      />

      {(bottomBannerText || bottomBannerHeading) && (
        <div className="bg-[#F2F7FB] text-center p-5 mt-10">
          <p className="text-lg mb-2">{bottomBannerHeading}</p>
          <p className="text-sm">{bottomBannerText}</p>
        </div>
      )}

    </div>
  );
}

ProductDetails.displayName = 'ProductDetails';
