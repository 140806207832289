export function Schema() {
  return {
    label: 'Image With Text Landing',
    key: 'imageWithTextLanding',
    category: 'Text',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/imagewithtextlayout.png?v=1721663759',
    fields: [
      {
        label: 'Dark Mode',
        name: 'darkMode',
        description: 'Set The Background dark and text white',
        component: 'toggle',
        defaultValue: false,
      },
      {
        label: 'Extra Top Padding',
        name: 'topPadding',
        description: 'Add extra top padding to section.',
        component: 'toggle',
        defaultValue: false,
      },
      {
        label: 'Extra Bottom Padding',
        name: 'bottomPadding',
        description: 'Add extra bottom padding to section',
        component: 'toggle',
        defaultValue: false,
      },
      {
        label: 'Align Text With Image',
        name: 'alignTextWithImage',
        description: 'Align text next to the image toggle',
        component: 'toggle',
        defaultValue: false,
      },
      {
        name: 'image',
        label: 'Image',
        component: 'image',
      },
      {
        label: 'Image (mobile)',
        name: 'mobileImage',
        component: 'image',
      },
      {
        label: 'Image Alignment',
        name: 'imageAlignment',
        component: 'select',
        options: [
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Right',
            value: 'right',
          },
        ],
        defaultValue: 'left',
      },
      {
        label: 'Eyebrow Icon Image',
        name: 'eyebrowIconImage',
        component: 'image',
      },
      {
        name: 'eyebrow',
        label: 'Eyebrow Text',
        component: 'text',
      },
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
      },
      {
        name: 'content',
        label: 'Content',
        component: 'markdown',
      },
    ],
  };
}
